import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import MarkdownContent from "../components/markdown";

export const CategoryPageTemplate = ({ category, products }) => (
  <div>
    <div className="container pt-5 pb-5">
      <nav aria-label="Page breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/categories">Categories</Link>
          </li>
          <li className="breadcrumb-item active">
            {category.name}
          </li>
        </ol>
      </nav>
      <h2>{category.name}</h2>
      <MarkdownContent content={category.description} />
    </div>
  </div>
);

CategoryPageTemplate.propTypes = {
  category: PropTypes.object,
};

const CategoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CategoryPageTemplate category={frontmatter} />
    </Layout>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CategoryPage;

export const pageQuery = graphql`
  query CategoryPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        description
      }
    }
  }
`;
